import React from "react";
import './App.css';

function Elijah() {




    return (
        < div className="eli" >
       <h1 style={{marginTop:'0%'}}>4th section </h1>
       <h2>content coming soon! </h2>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>
            <p>for development purposes</p>

        
        </div>
    )
}
export default Elijah;