import React from "react";
import './App.css';

function Home() {




    return (
        < div className="App App-header" >
            <h1>Welcome Home!</h1>
        <div style={{ height: '200px' }} className="teach"></div>
        <p>Website currently under construction</p>
        <p style={{ color: 'yellow' }}>Come back soon!</p>

        
        </div>
    )
}
export default Home;